import React from "react";
import ReactDOM from "react-dom/client";


//Site elements
//import Navbar from "./elements/Navbar"
import App from "./App"

//Context
import { DatabaseContextProvider } from "./contexts/DatabaseContextProvider";


import reportWebVitals from "./reportWebVitals";





const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <DatabaseContextProvider>    
      <App />
    </DatabaseContextProvider>

  </React.StrictMode>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
